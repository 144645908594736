<!-- 高压侧-馈线 -->
<template>
  <div id="feeder-line">
    <div class="tree">
      <div class="tree-title">回路</div>
      <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData"
        :check-on-click-node="false" :props="defaultProps" node-key="deviceNumber" :filter-node-method="filterNode"
        @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>高压侧</span>
      </div>
      <div class="content">
        <ul class="info">
            <div class="info-content" v-for="(item,index) in pointList" :key="index">
              <div :class="item.value=='关闭' ? 'danger':''"></div>
              <span>{{ item.name }}</span>
            </div>
            <!-- <div class="info-content">
              <div class="danger"></div>
              <span>跳位开入</span>
            </div> -->
            
        

        </ul>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="nowTime" label="采集时间" width="200">
            <template slot-scope="scope">
              {{ scope.row.nowTime.substr(11, 8) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ia" label="A相电流(A)">
          </el-table-column>
          <el-table-column align="center" prop="ib" label="B相电流(A)">
          </el-table-column>
          <el-table-column align="center" prop="ic" label="C相电流(A)">
          </el-table-column>
          <el-table-column align="center" prop="ua" label="A相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="ub" label="B相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="uc" label="C相电压(V)">
          </el-table-column>
          <el-table-column align="center" prop="pe" label="有功功率(Wh)">
          </el-table-column>
          <el-table-column align="center" prop="qe" label="无功功率(var)">
          </el-table-column>
          <el-table-column align="center" prop="cosqE" label="功率因数">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
        type: [],
      },
      select: 0,
      deviceNumber: '',
      stationNumber:'',
      
      tableData: [],
      pointList:[],
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "deviceNumber",
        label: "loopName",
      },
      treeData: [
      ],
    
    };
  },

  mounted() {
    // this.drawChart();
    this.stationNumber=sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    this.getTreeList()
  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '高压侧')
    this.$store.commit("selectChildren", "");
  },

  methods: {
    ///获取列表
    getMainList(){
      let obj={
        stationNumber: this.stationNumber,
        deviceNumber: this.deviceNumber,
        startTime:'',
        endTime:'',
        // stationNumber: 'ZD0113-01',
        // deviceNumber: '005',

      }
      var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      emissionsCarbonApi.getMainList(obj).then(res=>{
        this.tableData=res.data
        // console.log('111111', this.tableData);

      })
    },
    ///获取点位
    getMainPoint(){
      let obj={
        stationNumber: this.stationNumber,
        deviceNumber: this.deviceNumber,
        // stationNumber: 'ZD0113-01',
        // deviceNumber: '005',
      }
      emissionsCarbonApi.getMainPoint(obj).then(res=>{
        this.pointList = Object.keys(res.data).map(key => {
            return {
                name: key,
                value: res.data[key]
            };
        });
      })
    },
    getTreeList(){
      emissionsCarbonApi.getLoopList({ stationNumber: this.stationNumber, position: 1 }).then(res => {
        // this.formData.deviceNumber = res.data[0].deviceNumber
        this.deviceNumber = res.data[0].deviceNumber
        //获取点位
        this.getMainPoint()
        this.getMainList()
        this.treeData = res.data
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.treeData[0].deviceNumber); // 默认选中节点第一个
      });
    })
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
      this.deviceNumber = checkedNodes.deviceNumber
      this.getMainPoint()
        this.getMainList()
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },

  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#feeder-line {
  width: 100%;
  min-height: 903px;
  min-width: 1621px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.info {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.info li {
  width: 250px;
  border-radius: 3px;
  background-color: rgba(5, 53, 125, 0.12);
  box-sizing: border-box;
  padding: 16px 0 8px 30px;
}

.info-content {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 2.16rem;
  /* border: 1px solid #000; */
  margin: 0 10px;
  background-color: rgba(5, 53, 125, 0.12);
  padding: 16px 0 8px 30px;
}

.info-content div {
  width: 12px;
  height: 12px;
  background-color: #14bf9b;
  border-radius: 50%;
  margin-right: 15px;
}

.info-content span {
  color: #d8e3ff;
  font-size: 14px;
}

.info .special {
  width: 520px;
  display: flex;
}

.info-content .danger {
  background-color: #d12300;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}
::v-deep .el-table__body-wrapper {
  max-height: 75vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
